import { Matrix, SelectedResponse } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  MatrixItem,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  getMultipleChoiceInstructions,
  getMultipleChoiceMatrixDesktopInstructions,
  getSetting
} from 'utils/question'
import { createResponseOptionChoice } from 'utils/responseChoice'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoMatrixRows,
  getVisibleMatrixRows
} from 'utils/matrixRowsMaskingLogic'
import { getVisibleResponseOptions } from 'utils/responseOptionsMaskingLogic'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import {
  addEntriesHiddenByLooping,
  getEntriesToHideByLooping
} from 'utils/hiddenByLooping'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'
import { getResponseChoiceIds } from './Entry.utils'

interface Props {
  entry: QuestionnaireEntry
}

const scrollTop = () => {
  setTimeout(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, 0)
}

const MatrixEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as MatrixItem
  const limit = getSetting(entryItem.settingValues, SettingCodes.ChoiceLimit)
  const intervalMin =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMin)
    ) ?? undefined
  const intervalMax =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMax)
    ) ?? undefined
  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(entryItem)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const sendResponseOptionChoices = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const mandatoryParams = useQuestionnaireParams()

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    matrixInstructions: {
      singleSelectDesktop: (
        <FormattedMessage
          id="question_matrixSelectOneAnswerDesktop"
          defaultMessage="Select one answer per row"
        />
      ),
      multiSelectDesktop: (
        <FormattedMessage
          id={getMultipleChoiceMatrixDesktopInstructions(
            limit,
            intervalMin,
            intervalMax,
            entryItem.responseOptions.length
          )}
          defaultMessage="Select up to {count} answers per row"
          values={{
            count: <b>{limit ?? intervalMax}</b>,
            min: <b>{intervalMin}</b>
          }}
        />
      ),
      singleSelectMobile: (
        <FormattedMessage
          id="question_singleChoiceInstructions"
          defaultMessage="Select one answer"
        />
      ),
      multiSelectMobile: (
        <FormattedMessage
          id={getMultipleChoiceInstructions(
            limit,
            intervalMin,
            intervalMax,
            entryItem.responseOptions.length
          )}
          defaultMessage="Select up to {count} answers"
          values={{
            count: <b>{limit ?? intervalMax}</b>,
            min: <b>{intervalMin}</b>
          }}
        />
      )
    }
  }

  const getResponseOptionChoices: (
    selectedResponses: SelectedResponse[]
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selectedResponse = selectedResponses.find(
          (sr) => sr.selectedPosition === responseOption.position
        )
        return createResponseOptionChoice(responseOption, selectedResponse)
      }) || []
    )
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.matrixTitle.matrixTitleId
    )
    getNextEntryPosition()
  }

  const entryItemWithFilteredMatrixRowsAndColumns = getVisibleMatrixRows(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    getVisibleResponseOptions(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem
    )
  )

  if (
    entryHasNoMatrixRows(entryItemWithFilteredMatrixRowsAndColumns.matrixRows)
  ) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.matrixTitle.matrixTitleId
    )
    getNextEntryPosition()
    return null
  }

  const handleNext: (selectedResponses: SelectedResponse[][]) => void = (
    selectedResponses
  ) => {
    const { looping } = entry
    const persistentRespondentChoice: PersistentRespondentChoice[] = []
    selectedResponses.forEach((_, index) => {
      const { matrixTitle, matrixRows } =
        entryItemWithFilteredMatrixRowsAndColumns
      const respondentChoice = {
        questionId: matrixRows[index].question.questionId,
        matrixTitleId: matrixTitle.matrixTitleId,
        responseChoiceIds: getResponseChoiceIds(
          entryItem,
          selectedResponses[index]
        )
      }
      persistRespondentChoice(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        respondentChoice
      )
      persistentRespondentChoice.push(respondentChoice)
    })

    if (looping) {
      const entriesToHide = getEntriesToHideByLooping(
        looping,
        persistentRespondentChoice
      )

      addEntriesHiddenByLooping(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        entriesToHide
      )
    }

    if (!preview) {
      selectedResponses.forEach((selectedResponseRow, index) => {
        sendResponseOptionChoices({
          questionId:
            entryItemWithFilteredMatrixRowsAndColumns.matrixRows[index].question
              .questionId,
          entry,
          entryType: entry.entryType,
          renderingDateTime,
          responseChoices: getResponseOptionChoices(selectedResponseRow),
          matrix: {
            matrixTitleId:
              entryItemWithFilteredMatrixRowsAndColumns.matrixTitle
                .matrixTitleId,
            rowPosition:
              entryItemWithFilteredMatrixRowsAndColumns.matrixRows[index]
                .position,
            renderedRowPosition:
              entryItemWithFilteredMatrixRowsAndColumns.matrixRows[index]
                .renderedPosition
          }
        })
      })
    }
    getNextEntryPosition([])
  }

  return (
    <Matrix
      item={entryItemWithFilteredMatrixRowsAndColumns}
      timer={timer}
      progress={progress}
      preview={preview}
      loc={loc}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      onMobileNext={scrollTop}
    />
  )
}

export default MatrixEntry
