import { MultipleChoice, SelectedResponse } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import {
  QuestionItem,
  QuestionKind,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { createResponseOptionChoice } from 'utils/responseChoice'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getVisibleResponseOptions
} from 'utils/responseOptionsMaskingLogic'
import {
  addEntriesHiddenByLooping,
  getEntriesToHideByLooping
} from 'utils/hiddenByLooping'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import { isSettingEnabled } from 'utils/question'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'
import { MultipleChoiceLocInstructions } from './MultipleChoiceLocInstructions'
import { getResponseChoiceIds } from './Entry.utils'

interface Props {
  entry: QuestionnaireEntry
}

const MultipleChoiceEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem

  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(entryItem)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const sendResponseOptionChoices = useSendResponseChoice()
  const { formatMessage } = useIntl()
  const mandatoryParams = useQuestionnaireParams()

  const getResponseOptionChoices: (
    selectedResponses: SelectedResponse[]
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selectedResponse = selectedResponses.find(
          (sr) => sr.selectedPosition === responseOption.position
        )
        return createResponseOptionChoice(responseOption, selectedResponse)
      }) || []
    )
  }

  const handleNext: (selectedResponses: SelectedResponse[]) => void = (
    selectedResponses
  ) => {
    const respondentChoice = {
      questionId: entryItem.question.questionId,
      responseChoiceIds: getResponseChoiceIds(entryItem, selectedResponses)
    }
    persistRespondentChoice(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      respondentChoice
    )

    const { looping, questionKind } = entry

    if (looping && questionKind === QuestionKind.QuestionnaireKind) {
      const entriesToHide = getEntriesToHideByLooping(looping, [
        respondentChoice
      ])

      addEntriesHiddenByLooping(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        entriesToHide
      )
    }

    if (!preview) {
      sendResponseOptionChoices({
        questionId: entryItem.question.questionId,
        entry,
        entryType: entry.entryType,
        renderingDateTime,
        responseChoices: getResponseOptionChoices(selectedResponses)
      })
    }

    const selectedResponseOptionPositions = selectedResponses.map(
      (selectedResponse) => selectedResponse.selectedPosition
    )
    getNextEntryPosition(selectedResponseOptionPositions)
  }

  if (
    isSettingEnabled(entryItem.settingValues, SettingCodes.AutoAnswerAllSetting)
  ) {
    const selectedResponses =
      entryItem.responseOptions?.map((responseOption) => {
        return {
          selectedPosition: responseOption.position,
          timestamp: renderingDateTime
        }
      }) || []
    handleNext(selectedResponses)
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.question.questionId
    )
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions = getVisibleResponseOptions(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem
  )

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.question.questionId
    )
    getNextEntryPosition()
  }

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <MultipleChoiceLocInstructions
        settingValues={entryItem.settingValues}
        responseOptions={entryItemWithFilteredResponseOptions.responseOptions}
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    })
  }

  return (
    <MultipleChoice
      item={entryItemWithFilteredResponseOptions}
      loc={loc}
      progress={progress}
      timer={timer}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default MultipleChoiceEntry
